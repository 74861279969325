import { api } from '@/const/axiosApi'

export default {
  namespaced: true,

  state: {
    bonuses: [],
    bonusLogs: [],
    bonusLogsMeta: {},
  },

  actions: {
    async getBonuses({ commit, dispatch }) {
      try {
        const { data } = await api.get(`/api/v1/bonus-accounts`)
        commit('SET_BONUSES', data.data)
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },

    async loadUserBonuses({ commit, dispatch, rootState }, payload) {
      const roles = rootState.dictionaries.rolesDictionary
      const userRoles = rootState.profile.userRoles
      const requiredRoles = [roles.ADMIN, roles.SUPPORT]
      const hasAnyRequiredRole = requiredRoles.some((role) => userRoles.includes(role))
      const url = hasAnyRequiredRole
        ? `/api/v1/bonus-accounts/user/${payload.userId}`
        : `/api/v1/companies/${payload.companyId}/bonus-accounts/user/${payload.userId}`
      try {
        const { data } = await api.get(url)
        commit('SET_BONUSES', data.data)
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },

    async plusUserBonuses({ dispatch }, payload) {
      const { userId, amount, companyId, scope = 'local' } = payload
      try {
        await api.post(`/api/v1/companies/${companyId}/bonus-accounts/${userId}/${scope}`, { amount })
        dispatch('logs/displaySuccessAlert', { message: `Нараховано ${amount}грн. бонусів` }, { root: true })
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },

    async minusUserBonuses({ dispatch }, payload) {
      const { userId, amount, companyId, scope = 'local' } = payload
      const data = {
        company: scope === 'local' ? +companyId : null,
        amount,
      }
      try {
        await api.post(`/api/v1/companies/${companyId}/bonus-accounts/${userId}/withdraw`, data)
        dispatch('logs/displaySuccessAlert', { message: `Списано ${amount}грн. бонусів` }, { root: true })
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },

    async getBonusLogs({ commit, dispatch }, { companyId, userId, page = 1, limit = 10, sort, createdAt } = {}) {
      const url = userId ? `/api/v1/companies/${companyId}/bonus-account-history/${userId}` : `/api/v1/bonus-account-history`
      let query = { page, limit }
      const optionalParams = { companyId, sort, createdAt }
      query = {
        ...query,
        ...Object.fromEntries(Object.entries(optionalParams).filter(([key, value]) => !!key && !!value)),
      }
      try {
        const { data } = await api.get(url, { params: query })
        commit('SET_PROFILE_BONUS_LOGS', data.data)
        commit('SET_PROFILE_BONUS_LOGS_META', data.meta)
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
  },

  mutations: {
    SET_BONUSES(state, bonuses) {
      state.bonuses = bonuses
    },
    SET_PROFILE_BONUS_LOGS(state, logs) {
      state.bonusLogs = logs
    },
    SET_PROFILE_BONUS_LOGS_META(state, meta) {
      state.bonusLogsMeta = meta
    },
  },
}
