<template>
  <div
    v-if="currentCompany"
    class="menu-wrapper"
    :style="{ 'margin-left': isMenuCollapsed ? '-256px' : 0 }"
    @mouseenter="showMenuHideButton = true"
    @mouseleave="showMenuHideButton = false"
  >
    <div class="main-menu">
      <v-treeview
        :items="filteredMenuItems"
        open-on-click
        activatable
        hoverable
        transition
        dense
        :active.sync="active"
        :open.sync="opened"
      >
        <template v-slot:prepend="{ item }">
          <router-link v-if="item.link" :to="getFullLink(item.link)" class="menu-link">
            <v-icon class="cursor-pointer">{{ item.icon }}</v-icon>
          </router-link>
          <v-icon v-else class="cursor-pointer">{{ item.icon }}</v-icon>
        </template>
        <template v-slot:label="{ item }">
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <router-link v-if="item.link" :to="getFullLink(item.link)" v-bind="attrs" v-on="on" class="menu-link">
                {{ item.name }}
              </router-link>
              <span v-else class="cursor-pointer" v-bind="attrs" v-on="on">{{ item.name }}</span>
            </template>
            {{ item.name }}
          </v-tooltip>
        </template>
      </v-treeview>
    </div>
    <div
      class="hide-button"
      @click="isMenuCollapsed = !isMenuCollapsed"
      :style="{ display: showMenuHideButton || isMenuCollapsed ? 'flex' : 'none' }"
    >
      <v-icon>{{ isMenuCollapsed ? 'mdi-menu-right' : 'mdi-menu-left' }}</v-icon>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { ROUTER_LINKS } from '@/const/router-links.enum'
import { ROLE } from '@/const/roles.enum'

export default {
  name: 'NavigationMenu',

  data: () => ({
    isMenuCollapsed: false,
    showMenuHideButton: false,
    active: [],
    opened: [],
    menuItems: [
      {
        id: 1,
        name: 'Головна',
        icon: 'mdi-home-outline',
        link: ROUTER_LINKS.HOME,
        roles: [ROLE.ADMIN, ROLE.OWNER, ROLE.ACCOUNTANT, ROLE.TECHNICIAN, ROLE.SUPPORT],
        children: [
          {
            id: 11,
            name: 'Головний звіт',
            link: ROUTER_LINKS.DASHBOARD + ROUTER_LINKS.DASHBOARD_MAIN,
            collapseMenu: true,
          },
          {
            id: 12,
            name: 'Фінансовий звіт',
            link: ROUTER_LINKS.DASHBOARD + ROUTER_LINKS.DASHBOARD_FINANCE,
            roles: [ROLE.ADMIN, ROLE.OWNER, ROLE.ACCOUNTANT],
            collapseMenu: true,
          },
          {
            id: 13,
            name: 'Маркетинговий звіт',
            link: ROUTER_LINKS.DASHBOARD + ROUTER_LINKS.DASHBOARD_MARKETING,
            roles: [ROLE.ADMIN, ROLE.OWNER, ROLE.SUPPORT, ROLE.ACCOUNTANT],
            collapseMenu: true,
          },
          {
            id: 14,
            name: 'Технічний звіт',
            link: ROUTER_LINKS.DASHBOARD + ROUTER_LINKS.DASHBOARD_TECHNICAL,
            roles: [ROLE.ADMIN, ROLE.OWNER, ROLE.SUPPORT, ROLE.TECHNICIAN],
            collapseMenu: true,
          },
          // {
          //   id: 15,
          //   name: 'Підтримка звіт',
          //   link: ROUTER_LINKS.DASHBOARD + ROUTER_LINKS.DASHBOARD_SUPPORT,
          //   roles: [ROLE.ADMIN, ROLE.OWNER, ROLE.SUPPORT, ROLE.TECHNICIAN],
          //   collapseMenu: true,
          // },
        ],
      },
      {
        id: 2,
        name: 'Моніторинг',
        icon: 'mdi-monitor',
        roles: [ROLE.ADMIN, ROLE.OWNER, ROLE.ACCOUNTANT, ROLE.TECHNICIAN, ROLE.SUPPORT],
        children: [
          {
            id: 21,
            name: 'Список',
            icon: 'mdi-list-status',
            link: ROUTER_LINKS.MONITORING,
            collapseMenu: true,
          },
          /*{
            id: 22,
            name: 'Карта',
            icon: 'mdi-map-marker-radius',
          },*/
        ],
      },
      {
        id: 3,
        name: 'Фінанси',
        icon: 'mdi-finance',
        roles: [ROLE.ADMIN, ROLE.OWNER, ROLE.ACCOUNTANT, ROLE.SUPPORT],
        children: [
          {
            id: 31,
            name: 'Платежі',
            icon: 'mdi-cash',
            link: ROUTER_LINKS.PAYMENTS,
          },
          {
            id: 32,
            name: 'Фінансові звіти',
            icon: 'mdi-chart-bell-curve-cumulative',
            link: ROUTER_LINKS.FINANCIAL_STATEMENTS,
            roles: [ROLE.ADMIN, ROLE.OWNER, ROLE.ACCOUNTANT],
          },
          {
            id: 33,
            name: 'Журнал інкасацій',
            icon: 'mdi-book-open-page-variant-outline',
            link: `${ROUTER_LINKS.FINANCIAL_ENCASHMENTS}`,
            roles: [ROLE.ADMIN, ROLE.OWNER, ROLE.ACCOUNTANT],
          },
          /*{
            title: 'Звіти по інкасаціям',
            icon: 'I',
            link: `${ROUTER_LINKS.FINANCIAL_ENCASHMENTS}/reports`,
          },*/
        ],
      },
      {
        id: 4,
        name: 'Маркетинг',
        icon: 'mdi-trademark',
        roles: [ROLE.ADMIN, ROLE.OWNER, ROLE.SUPPORT],
        children: [
          {
            id: 41,
            name: 'Клієнти',
            icon: 'mdi-account-multiple-outline',
            link: ROUTER_LINKS.CLIENTS,
          },
          // {
          //   id: 42,
          //   name: 'Акції',
          //   icon: 'A',
          //   link: ROUTER_LINKS.PROMOTIONS,
          // },
          // {
          //   id: 43,
          //   name: 'Пуш-повідомлення',
          //   icon: 'mdi-message-text-outline',
          //   link: ROUTER_LINKS.PUSH_NOTIFICATIONS_MAIN,
          // },
        ],
      },
      {
        id: 5,
        name: 'Обслуговування',
        icon: 'mdi-wrench-outline',
        roles: [ROLE.ADMIN, ROLE.OWNER, ROLE.TECHNICIAN, ROLE.SUPPORT],
        children: [
          {
            id: 51,
            name: 'Події пристроїв',
            icon: 'mdi-flash-outline',
            link: ROUTER_LINKS.DEVICE_EVENTS_MAINTENANCE,
          },
        ],
      },
      {
        id: 6,
        name: 'Адміністрування',
        icon: 'mdi-folder-open-outline',
        roles: [ROLE.ADMIN, ROLE.OWNER, ROLE.ACCOUNTANT, ROLE.TECHNICIAN, ROLE.SUPPORT],
        children: [
          { id: 61, name: 'Компанія', icon: 'mdi-chart-line', link: ROUTER_LINKS.COMPANIES_CARD },
          { id: 62, name: 'Персонал', icon: 'mdi-account-multiple-check-outline', link: ROUTER_LINKS.STAFF },
          { id: 63, name: 'Партнери', icon: 'mdi-bank-outline', link: ROUTER_LINKS.PARTNERS },
          {
            id: 64,
            name: 'Торгівельні точки',
            icon: 'mdi-map-marker-radius-outline',
            link: ROUTER_LINKS.SERVICE_POINTS,
          },
          { id: 65, name: 'Термінали', icon: 'mdi-sitemap-outline', link: ROUTER_LINKS.TERMINALS },
          { id: 66, name: 'Автомати', icon: 'mdi-power-plug-outline', link: ROUTER_LINKS.DEVICES },
          // { title: 'Товари', icon: 'mdi-cart-outline' },
        ],
      },
      {
        id: 7,
        name: 'Адмін',
        icon: 'mdi-account-key-outline',
        roles: [ROLE.ADMIN],
        children: [
          {
            id: 71,
            name: 'Компанії',
            icon: 'mdi-format-list-bulleted-square',
            link: ROUTER_LINKS.COMPANIES_LIST,
          },
          {
            id: 72,
            name: 'Налаштування системи',
            icon: 'mdi-wrench-cog-outline',
            link: ROUTER_LINKS.SETTINGS,
          },
          {
            id: 73,
            name: 'Дашборди',
            icon: 'mdi-view-dashboard-edit-outline',
            link: ROUTER_LINKS.DASHBOARD_CONSTRUCTOR_LIST,
          },
        ],
      },
      {
        id: 8,
        name: 'Підтримка CloudMate',
        icon: 'mdi-information-outline',
        roles: [ROLE.ADMIN, ROLE.SUPPORT],
        children: [
          {
            id: 81,
            name: 'Головна',
            icon: 'mdi-home-outline',
            link: ROUTER_LINKS.ADMIN_DASHBOARD,
          },
          {
            id: 82,
            name: 'Користувачі',
            icon: 'mdi-account-group-outline',
            link: ROUTER_LINKS.USERS,
          },
          {
            id: 83,
            name: 'Конструктори',
            icon: 'mdi-view-dashboard-outline',
            children: [
              {
                id: 831,
                name: 'Категорії та статті',
                icon: 'mdi-book-multiple-outline',
                link: ROUTER_LINKS.INFO_CONSTRUCTOR_CATEGORIES,
              },
              {
                id: 832,
                name: 'Події, статуси, команди',
                icon: 'mdi-flash-outline',
                link: ROUTER_LINKS.INFO_CONSTRUCTOR_EVENTS_GROUP_LIST,
              },
              {
                id: 833,
                name: 'Підказки (Tooltips)',
                icon: 'mdi-tooltip-text-outline',
                link: ROUTER_LINKS.INFO_CONSTRUCTOR_TOOLTIPS_LIST,
              },
            ],
          },
          {
            id: 84,
            name: 'Акції',
            icon: 'A',
            link: ROUTER_LINKS.PROMOTIONS,
          },
          {
            id: 85,
            name: 'Пуш-повідомлення',
            icon: 'mdi-message-text-outline',
            link: ROUTER_LINKS.PUSH_NOTIFICATIONS_MAIN,
          },
          {
            id: 86,
            name: 'Логи',
            icon: 'mdi-book-open-page-variant-outline',
            roles: [ROLE.ADMIN, ROLE.SUPPORT],
            children: [
              {
                id: 861,
                name: 'Події та статуси',
                icon: 'mdi-history',
                link: ROUTER_LINKS.DEVICE_EVENTS,
              },
              {
                id: 862,
                name: 'Пакети пристроїв',
                icon: 'mdi-package-check',
                link: ROUTER_LINKS.PACKAGES,
              },
            ],
          },
          {
            id: 87,
            name: 'Прошивки',
            icon: 'mdi-chip',
            link: ROUTER_LINKS.FIRMWARES,
          },
        ],
      },
    ],
    right: null,
  }),

  watch: {
    active(newValue, oldValue) {
      if (!newValue.length) {
        const item = this.findMenuItemByPath(this.$route.path)
        if (item) {
          setTimeout(() => (this.active = [item.id]), 0)
          this.isMenuCollapsed = item.collapseMenu
        } else {
          this.isMenuCollapsed = false
        }
      } else {
        const menuItem = this.findItemInTree(this.filteredMenuItems, 'id', this.active[0])
        if (menuItem?.link && this.$route.path !== this.getFullLink(menuItem.link) && this.$route.path !== menuItem.link) {
          this.isMenuCollapsed = menuItem?.collapseMenu
          this.$router.push(this.getFullLink(menuItem?.link))
        }
        if (!menuItem || !menuItem.link) {
          setTimeout(() => (this.active = oldValue))
        }
      }
    },
    '$route.path'(newValue) {
      if (newValue === '/main') {
        this.active = [1]
      } else {
        const activeItem = this.findMenuItemByPath(newValue)
        if (activeItem) {
          this.active = [activeItem.id]
          let parent = this.findParentInTree(this.filteredMenuItems, activeItem.id)
          while (parent) {
            this.opened.push(parent.id)
            parent = this.findParentInTree(this.filteredMenuItems, parent.id)
          }
        } else {
          this.active = []
        }
      }
    },
  },

  computed: {
    ...mapState('companies', ['currentCompany']),
    ...mapState('common', ['navigationMenuMini']),

    filteredMenuItems() {
      return this.filterItems(this.menuItems, this.userRoles)
    },
  },

  methods: {
    ...mapMutations('common', ['SET_NAVIGATION_MENU_MINI']),

    filterItems(items, userRoles) {
      return items.reduce((filtered, item) => {
        if (this.isItemVisible(item, userRoles)) {
          const filteredItem = { ...item }
          if (filteredItem.children?.length) {
            filteredItem.children = this.filterItems(filteredItem.children, userRoles)
            if (filteredItem.children?.length) {
              filtered.push(filteredItem)
            }
          } else {
            filtered.push(filteredItem)
          }
        }
        return filtered
      }, [])
    },
    isItemVisible(item, userRoles) {
      if (item.roles?.length) {
        return item.roles.some((role) => userRoles.includes(role))
      }
      return true
    },
    findItemInTree(items, searchField, value) {
      for (const item of items) {
        if (item[searchField] === value) {
          return item
        }
        if (item.children) {
          const childItem = this.findItemInTree(item.children, searchField, value)
          if (childItem) {
            return childItem
          }
        }
      }
      return null
    },
    findParentInTree(items, id, parent = null) {
      for (const item of items) {
        if (item.id === id) {
          return parent
        }
        if (item.children) {
          const parentItem = this.findParentInTree(item.children, id, item)
          if (parentItem) {
            return parentItem
          }
        }
      }
      return null
    },
    findMenuItemByPath(path) {
      if (this.currentCompany) {
        let pos = path.indexOf(this.currentCompany.id)
        while (path[pos] !== '/') pos++
        const link = path.slice(pos + 1)
        return this.findItemInTree(this.filteredMenuItems, 'link', link)
      } else {
        return null
      }
    },
    getFullLink(link) {
      if (typeof link === 'object') {
        return {
          path: `/company/${this.currentCompany.id}/${link.path}`,
          ...link,
        }
      }
      return `/company/${this.currentCompany.id}/${link}`
    },
  },
}
</script>

<style lang="css" scoped>
.menu-wrapper {
  width: 272px;
  height: 100vh;
  position: relative;
  padding: 48px 16px 40px 0;
  transition: all ease 0.5s;
}

.main-menu {
  width: 256px;
  height: 100%;
  padding: 4px 0;
  overflow-y: auto;
  background-color: white;
  font-family: Roboto, sans-serif;
  font-weight: 500;
  font-size: 14px;
}

.cursor-pointer {
  cursor: pointer;
}

.hide-button {
  width: 16px;
  height: 48px;
  position: absolute;
  left: calc(100% - 16px);
  top: 140px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: white;
  font-family: Roboto, sans-serif;
  font-weight: bold;
  font-size: 20px;
  cursor: pointer;
  border-bottom-right-radius: 12px;
  border-top-right-radius: 12px;
  z-index: 4;
  &:hover {
    background: linear-gradient(to right, #ffffff, rgba(200, 200, 200, 0.87));
  }
}

.menu-link {
  display: block;
  color: black;
  text-decoration: none;
  font-weight: 300;
}
</style>
