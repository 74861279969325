import { api } from '@/const/axiosApi'

export default {
  namespaced: true,
  state: {
    allDictionaries: [],
    eventTypeGroupsDictionary: [],
    packageTypesDictionary: [],
    globalPromotionsDictionary: [],
    companyPromotionsDictionary: [],
    paymentTypesDictionary: [],
    pushNotificationTypesDictionary: [],
    pushNotificationRecipientsDictionary: [],
    pushNotificationEventsDictionary: [],
    pushNotificationVariablesDictionary: [],
    paymentStatusesDictionary: [],
    technicalContactTypesDictionary: [],
    personalContactTypesDictionary: [],
    machinesTypesDictionary: [],
    rolesDictionary: {},
    connectedDevicesTypesDictionary: [],
    terminalsModelsDictionary: [],
    localesDictionary: [],
    firmwareDevicesTypes: [],
    articlesCategoriesTypesDictionary: [],
  },
  actions: {
    async loadAllDictionaries({ commit, dispatch }) {
      try {
        const { data } = await api.get('/api/v1/dictionaries')
        commit('SET_ALL_DICTIONARIES', data.data)
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
    async loadDictionary({ commit, dispatch }, id) {
      try {
        if (id === 1) return //id: 1 ==> all dictionaries
        const { data } = await api.get(`/api/v1/dictionaries/${id}`)
        switch (id) {
          case 2:
            commit('SET_EVENT_TYPE_GROUPS_DICTIONARY', data.data)
            break
          case 4:
            commit('SET_PACKAGE_TYPES_DICTIONARY', data.data)
            break
          case 5:
            commit('SET_GLOBAL_PROMOTIONS_DICTIONARY', data.data)
            break
          case 6:
            commit('SET_COMPANY_PROMOTIONS_DICTIONARY', data.data)
            break
          case 9:
            commit('SET_PAYMENT_TYPES_DICTIONARY', data.data)
            break
          case 10:
            commit('SET_PUSH_NOTIFICATION_RECIPIENTS_DICTIONARY', data.data)
            break
          case 11:
            commit('SET_PUSH_NOTIFICATION_TYPES_DICTIONARY', data.data)
            break
          case 12:
            commit('SET_PAYMENT_STATUSES_DICTIONARY', data.data)
            break
          case 13:
            commit('SET_PUSH_NOTIFICATION_EVENTS_DICTIONARY', data.data)
            break
          case 14:
            commit('SET_PUSH_NOTIFICATION_VARIABLES_DICTIONARY', data.data)
            break
          case 15:
            commit('SET_PERSONAL_CONTACT_TYPES_DICTIONARY', data.data)
            break
          case 16:
            commit('SET_TECHNICAL_CONTACT_TYPES_DICTIONARY', data.data)
            break
          case 18:
            commit('SET_MACHINES_TYPES_DICTIONARY', data.data)
            break
          case 19:
            commit('SET_ROLES_DICTIONARY', data.data)
            break
          case 20:
            commit('SET_DEVICES_TYPES_DICTIONARY', data.data)
            break
          case 21:
            commit('SET_LOCALES_DICTIONARY', data.data)
            break
          case 22:
            commit('SET_TERMINALS_MODELS_DICTIONARY', data.data)
            break
          case 25:
            commit('SET_FIRMWARE_DEVICES_TYPES', data.data)
            break
          case 26:
            commit('SET_ARTICLES_CATEGORIES_TYPES', data.data)
            break
          default:
            break
        }
      } catch (e) {
        dispatch('logs/displayErrorAlert', e.response, { root: true })
      }
    },
  },
  mutations: {
    SET_ALL_DICTIONARIES(state, data) {
      state.allDictionaries = data
    },
    SET_EVENT_TYPE_GROUPS_DICTIONARY(state, data) {
      state.eventTypeGroupsDictionary = data
    },
    SET_PACKAGE_TYPES_DICTIONARY(state, data) {
      state.packageTypesDictionary = data
    },
    SET_GLOBAL_PROMOTIONS_DICTIONARY(state, data) {
      state.globalPromotionsDictionary = data
    },
    SET_COMPANY_PROMOTIONS_DICTIONARY(state, data) {
      state.companyPromotionsDictionary = data
    },
    SET_PAYMENT_TYPES_DICTIONARY(state, data) {
      state.paymentTypesDictionary = data
    },
    SET_PUSH_NOTIFICATION_TYPES_DICTIONARY(state, data) {
      state.pushNotificationTypesDictionary = data
    },
    SET_PUSH_NOTIFICATION_RECIPIENTS_DICTIONARY(state, data) {
      state.pushNotificationRecipientsDictionary = data
    },
    SET_PUSH_NOTIFICATION_EVENTS_DICTIONARY(state, data) {
      state.pushNotificationEventsDictionary = data
    },
    SET_PUSH_NOTIFICATION_VARIABLES_DICTIONARY(state, data) {
      state.pushNotificationVariablesDictionary = data
    },
    SET_PAYMENT_STATUSES_DICTIONARY(state, data) {
      state.paymentStatusesDictionary = data
    },
    SET_TECHNICAL_CONTACT_TYPES_DICTIONARY(state, data) {
      state.technicalContactTypesDictionary = data
    },
    SET_PERSONAL_CONTACT_TYPES_DICTIONARY(state, data) {
      state.personalContactTypesDictionary = data
    },
    SET_MACHINES_TYPES_DICTIONARY(state, data) {
      state.machinesTypesDictionary = data
    },
    SET_ROLES_DICTIONARY(state, data) {
      state.rolesDictionary = data
    },
    SET_DEVICES_TYPES_DICTIONARY(state, data) {
      state.connectedDevicesTypesDictionary = data
    },
    SET_LOCALES_DICTIONARY(state, data) {
      state.localesDictionary = data
    },
    SET_TERMINALS_MODELS_DICTIONARY(state, data) {
      state.terminalsModelsDictionary = data
    },
    SET_FIRMWARE_DEVICES_TYPES(state, data) {
      state.firmwareDevicesTypes = data.filter((item) => item.id !== 6)
    },
    SET_ARTICLES_CATEGORIES_TYPES(state, data) {
      state.articlesCategoriesTypesDictionary = data
    },
  },
  getters: {},
}
