/*export const ADDRESSES_SCHEMA = Object.freeze({
  country: '',
  city: '',
  street: '',
  building_number: '',
  latitude: null,
  longitude: null,
  type: null,
})*/

export const COMPANY_SCHEMA = Object.freeze({
  id: null,
  name: '',
  tin: '',
  description: '',
  site: '',
  status: 1,
  owner: {
    id: null,
    first_name: '',
    last_name: '',
    phone: '',
  },
  contacts: {
    personal: [
      {
        full_name: '',
        position: '',
        phone: '',
        contact_type: [
          {
            type: '',
            value: '',
          },
        ],
      },
    ],
    technical: [
      {
        type: '',
        value: '',
      },
    ],
  },
  liqpay_id: '',
  payment_commission: null,
  phone: '',
  company_type: null,
  /*address: {
    country: '',
    city: '',
    street: '',
    building_number: '',
    latitude: null,
    longitude: null,
  },*/
})

export const TERMINAL_SCHEMA = Object.freeze({
  imei: '',
  producer: '',
  model: '',
  bill_acceptor_capacity: null,
  location: '',
  is_searchable: true,
})

export const DEVICE_SCHEMA = Object.freeze({
  type: null,
  brand: '',
  model: '',
  inventory_number: '',
  serial_number: '',
  manufacture_date: '',
  purchase_date: '',
  shelves_count: null,
})

export const SERVICE_POINTS_SCHEMA = Object.freeze({
  balance_holder: null,
  name: '',
  location: [],
  habitants_count: null,
  floor_count: null,
  contacts: null,
  is_searchable: true,
  schedule: '',
  availability: true,
  description: '',
})

export const PARTNERS_SCHEMA = Object.freeze({
  name: '',
  contacts: {
    personal: [
      {
        full_name: '',
        position: '',
        phone: '',
        contact_type: [
          {
            type: '',
            value: '',
          },
        ],
      },
    ],
    technical: [
      {
        type: '',
        value: '',
      },
    ],
  },
  phone: '',
  company_type: null,
  /*address: {
    country: '',
    city: '',
    street: '',
    building_number: '',
    latitude: null,
    longitude: null,
  },*/
})

export const STAFF_SCHEMA = Object.freeze({
  user: {
    id: null,
  },
  start_cooperation: '',
  stop_cooperation: '',
  position: {
    id: null,
  },
})

export const USER_SCHEMA = Object.freeze({
  first_name: '',
  last_name: '',
  phone: '',
  contacts: { personal: [] },
  status: 0,
})

export const ADDITIONAL_INFO_CATEGORY_SCHEMA = Object.freeze({
  name: {
    uk: '',
    en: '',
    ru: '',
    pl: '',
    de: '',
  },
  category_type: null,
})

export const ADDITIONAL_INFO_ARTICLE_SCHEMA = {
  category: {
    id: null,
  },
  name: '@LOCALE',
  articles: '@LOCALE',
  event_code: {
    id: 0,
    protocol: '',
    event_type: null,
  },
  status: 1,
  identifier: null,
}
export const ADDITIONAL_INFO_EVENT_CODE_SCHEMA = Object.freeze({
  device_type: null,
  name: '@LOCALE',
  event_code: '',
  packet_event_type: null,
  protocol: '',
  event_type: {
    id: null,
  },
  short_description: '@LOCALE',
  colour: '',
})
export const ADDITIONAL_INFO_EVENT_TYPE_SCHEMA = Object.freeze({
  name: '@LOCALE',
  description: '@LOCALE',
  code: null,
  protocol: '',
})
export const ENCASHMENT_SCHEMA = Object.freeze({
  total_counter: 0,
  collector: {
    id: 0,
  },
  cash_sum: 0,
  actual_sum: 0,
  technical_payment: 0,
  payout: 0,
  difference: 0,
  comment: '',
  days_after_prev_encashment: 0,
  actual_notes_and_coins: [
    {
      notes: {
        one: 0,
        two: 0,
        five: 0,
        ten: 0,
        twenty: 0,
        fifty: 0,
        one_hundred: 0,
        two_hundred: 0,
        five_hundred: 0,
      },
      coins: {
        ten: 0,
        twenty_five: 0,
        fifty: 0,
        one_hundred: 0,
        two_hundred: 0,
        five_hundred: 0,
        ten_hundred: 0,
      },
    },
  ],
  notes_and_coins: [
    {
      notes: {
        one: 0,
        two: 0,
        five: 0,
        ten: 0,
        twenty: 0,
        fifty: 0,
        one_hundred: 0,
        two_hundred: 0,
        five_hundred: 0,
      },
      coins: {
        ten: 0,
        twenty_five: 0,
        fifty: 0,
        one_hundred: 0,
        two_hundred: 0,
        five_hundred: 0,
        ten_hundred: 0,
      },
    },
  ],
  balance_holder: {
    id: 0,
  },
  service_point: {
    id: 0,
  },
  terminal: {
    id: 0,
  },
  created_at: '',
})

export const FIRMWARE_SCHEMA = Object.freeze({
  available_to_update: [],
  chunk_count: null,
  description: '',
  device_type: 101,
  file: null,
  file_size: null,
  filename: '',
  is_available: true,
  supported_devices: [],
  terminal_model: '',
  version_name: '',
})

export const PROGRESS_DATA_SCHEMA = Object.freeze({
  id: '',
  title: '',
  percents: 0,
  status: 0,
  statusMessage: '',
  visible: true,
})

export const PROMOTION_SCHEMA = Object.freeze({
  amount: null,
  conditions: [],
  devices: [],
  device_types: [],
  description: null,
  expired_at: null,
  is_active: true,
  is_available_once: false,
  params: {
    show_promotion: false,
    flag_params: {},
  },
  service_points: [],
  title: null,
  type: null,
  users: [],
})

export const PUSH_NOTIFICATION_SCHEMA = Object.freeze({
  start_at: null,
  expired_at: null,
  type: null,
  event: null,
  receiver_type: null,
  is_active: true,
  is_save: null,
  is_one_time: false,
  receivers: [],
  companies: [],
  balance_holders: [],
  service_points: [],
  devices: [],
  device_types: [],
  content: [],
})

export const DASHBOARD_SCHEMA = Object.freeze({
  id: null,
  name: '',
  uid: '',
  is_active: false,
})
