<template>
  <v-app>
    <AlertMain />

    <Header />

    <div class="main-wrapper">
      <NavigationMenu
        v-if="!isLoginPage && !isRegistrationPage && !isVerificationPage && !isRestorePasswordPage"
      ></NavigationMenu>
      <div
        class="main-view-wrapper"
        :class="{ 'display-center': isLoginPage || isRegistrationPage || isRestorePasswordPage || isVerificationPage }"
      >
        <v-main>
          <OverlayLoaderGlobal />
          <router-view v-if="isInitialized" />
        </v-main>
      </div>
    </div>

    <Footer />
  </v-app>
</template>

<script>
import { mapActions } from 'vuex'

import Header from '@/components/Header'
import Footer from '@/components/Footer'
import NavigationMenu from '@/components/NavigationMenu'
import defineCurrentPage from '@/mixins/defineCurrentPage'
import SetPageTitle from '@/mixins/setPageTitle'
import { getLocalAccessToken, getLocalRefreshToken } from '@/helpers/getAuthToken'
// import { loadVueDevtools } from '@/helpers/loadVueDevtools'
import progressActivator from '@/mixins/progressActivator'

export default {
  name: 'App',

  components: {
    NavigationMenu,
    Footer,
    Header,
    AlertMain: () => import('@/components/common/AlertMain'),
    OverlayLoaderGlobal: () => import('@/components/common/OverlayLoaderGlobal'),
  },

  mixins: [defineCurrentPage, SetPageTitle, progressActivator],

  data() {
    return {
      isInitialized: false,
    }
  },

  async created() {
    // loadVueDevtools()
    await this.initialize()
    this.isInitialized = true
  },

  methods: {
    ...mapActions('init', ['initState']),

    async initialize() {
      const loggedIn = !!getLocalAccessToken() && !!getLocalRefreshToken()
      if (loggedIn) await this.initState()
    },
  },
}
</script>

<style>
/*remove scrollbar if content is not overflowing vertically*/
html {
  overflow-y: auto;
}
.ql-align-justify {
  text-align: justify;
}
.main-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  overflow: hidden;
}
.main-view-wrapper {
  width: 100%;
  height: 100vh;
  padding-right: 12px;
  overflow-x: hidden;
}
.display-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
</style>
