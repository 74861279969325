var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app-bar',{attrs:{"app":"","clipped-left":"","dense":""}},[(!_vm.isLoginPage && !_vm.isRegistrationPage && !_vm.isVerificationPage && !_vm.isRestorePasswordPage)?_c('SelectCompanyMenu'):_vm._e(),(
      _vm.currentCompany &&
      _vm.currentCompany.logo &&
      !_vm.isLoginPage &&
      !_vm.isRegistrationPage &&
      !_vm.isVerificationPage &&
      !_vm.isRestorePasswordPage
    )?_c('v-avatar',{staticClass:"company-avatar mx-3",attrs:{"size":"36"},on:{"click":_vm.navigateToDashboard}},[_c('img',{attrs:{"src":`${_vm.baseURL}/${_vm.currentCompany.logo}`,"alt":"logo"}})]):_vm._e(),(
      _vm.currentCompany &&
      _vm.currentCompany.name &&
      !_vm.isLoginPage &&
      !_vm.isRegistrationPage &&
      !_vm.isVerificationPage &&
      !_vm.isRestorePasswordPage
    )?_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.currentCompany.name)+" ")]):_vm._e(),_c('v-spacer'),(!_vm.isLoginPage && !_vm.isRegistrationPage && !_vm.isVerificationPage && !_vm.isRestorePasswordPage)?_c('div',{staticClass:"mt-1 mr-6"},[_c('v-switch',{attrs:{"color":"success","dense":"","hide-details":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"caption"},[_vm._v("Підказки")])]},proxy:true}],null,false,465956042),model:{value:(_vm.computedShowTooltips),callback:function ($$v) {_vm.computedShowTooltips=$$v},expression:"computedShowTooltips"}})],1):_vm._e(),(_vm.user)?_c('v-toolbar-title',{staticClass:"mr-2"},[_c('span',{staticClass:"caption"},[_vm._v(_vm._s(_vm.user.first_name)+" "+_vm._s(_vm.user.last_name))])]):_vm._e(),(!_vm.isLoginPage && !_vm.isRegistrationPage && !_vm.isVerificationPage && _vm.user)?_c('ProfileMenu',{attrs:{"avatar":_vm.user.avatar}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }